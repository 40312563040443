import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button, Grid, useTheme, IconButton, Accordion, AccordionDetails, AccordionSummary, Snackbar, Alert, Tabs, Tab, Box, Card, CardContent, Tooltip } from "@mui/material";
import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import FormatIndentIncreaseRoundedIcon from '@mui/icons-material/FormatIndentIncreaseRounded';
import FormatIndentDecreaseRoundedIcon from '@mui/icons-material/FormatIndentDecreaseRounded';
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';

import 'draft-js/dist/Draft.css';
import { Editor, EditorState, RichUtils, convertFromRaw, convertToRaw } from "draft-js";

import { request } from "@/Api";
import { Heading } from "@/common/StyledComponents";
import { selectToken, setToken } from "@/auth/authSlice"
import OrganizationsSection from "@/account/OrganizationsSection"
import { API_BASE_URL } from '@/config';
import ApiKeys from './ApiKeys';


export default function SuperAdmin(){
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const [tabIndex, setTabIndex] = useState(0)
    const [stats, setStats] = useState({})
    const [orgs, setOrgs] = useState([])
    const [templateOrg, setTemplateOrg] = useState(null)
    const [saved, setSaved] = useState(false)
    const [error, setError] = useState(false)

    const token = useSelector(selectToken)
    const tokenRef = useRef()

    const getStats = () => {
        request.get("admin/stats").then(response => {
            setStats(response.data)
        })
    }

    const getOrgs = () => {
        request.get("admin/organizations")
        .then(response=>{
            setOrgs(response.data.filter(org => !org.subscription || org.subscription.name != "Template"))
            setTemplateOrg(response.data.find(org => org.subscription && org.subscription.name == "Template"))
        })
    }

    const copyTokenToClipboard = () => {
        tokenRef.current.type = "text"
        tokenRef.current.select()
        document.execCommand("copy")
        tokenRef.current.type = "hidden"
    }

    const goToDoc = () => {
        window.open(`${API_BASE_URL}/docs`, '_blank').focus()
    }
    
    const goToTemplates = () => {
        request.post(`enter-organization/${templateOrg.id}`).then((response) => {
            dispatch(setToken(response.data))
            navigate("/template-bids")
        })
    }

    const handleTabChange = (event, newTab) => {
        setTabIndex(newTab)
    }

    const handleCloseSave = () => {
        setSaved(false)
    }
    const handleCloseError = () => {
        setError("")
    }

    useEffect(()=>{
        getOrgs()
        getStats()
    }, [])

    return (
        <>
            <Heading>⚠️ SUPER ADMIN PANEL ⚠️</Heading>
            <span style={{color: "gray"}}>With great power comes great responsibility</span>

            <Grid container justifyContent="space-between" sx={{marginTop: "24px"}}>
                <Grid item>
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Workspaces" />
                        <Tab label="Stats" />
                        <Tab label="API Keys" />
                        <Tab label="Public Terms" />
                    </Tabs>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={goToTemplates}>Edit Global Template Bids</Button>
                    <Button variant="contained" onClick={goToDoc} sx={{marginLeft: "24px"}}>Open API Docs</Button>
                    <Tooltip title="Copy token to clipboard">
                        <IconButton onClick={copyTokenToClipboard} sx={{marginLeft: "24px"}}><ContentPasteGoIcon /></IconButton>
                    </Tooltip>
                    <input type="hidden" value={token} ref={tokenRef} />
                </Grid>
            </Grid>

            <TabPanel value={tabIndex} index={0}>
                <OrganizationsSection data={orgs} setData={setOrgs} setSaved={setSaved} setError={setError} isSuperAdmin />
            </TabPanel>
            
            <TabPanel value={tabIndex} index={1}>
                <Grid container spacing={2}>
                    {Object.entries(stats).map(([group, data]) => (
                        <Grid item key={group} flexGrow={1}>
                            <Card>
                                <CardContent>
                                    <h4 style={{textTransform: "uppercase"}}>{group.replace(/_/g, " ")}</h4>
                                    <ul style={{lineHeight: "32px"}}>
                                        {Object.entries(data).map(([key, value]) => (
                                            <li key={`${group}_${key}`}>{`${key.replace(/_/g, " ")}:`} {value}</li>
                                        ))}
                                    </ul>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </TabPanel>

            <TabPanel value={tabIndex} index={2}>
                <ApiKeys />
            </TabPanel>

            <TabPanel value={tabIndex} index={3}>
                <RichTextEditorSection name={"Terms of Use"}/>
                <RichTextEditorSection name={"Privacy Policy"}/>
            </TabPanel>

            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "left" }}
                open={saved}
                onClose={handleCloseSave}
                autoHideDuration={2000}
            >
                <Alert
                    variant="filled"
                    severity="success"
                    sx={{ width: '100%' }}
                    onClose={handleCloseSave}
                >
                    Workspace updated
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center" }}
                open={error != ""}
                onClose={handleCloseError}
            >
                <Alert
                    variant="filled"
                    severity="error"
                    sx={{ width: '100%' }}
                    onClose={handleCloseError}
                >
                    { error }
                </Alert>
            </Snackbar>
        </>
    )
}

const TabPanel = ({ children, value, index, ...props }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        {...props}
    >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
)

const RichTextEditorSection = ({name}) => {
    const theme = useTheme()
    const editor = useRef(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const getTerm = () => {
        request.get(`public-terms/${name}`)
        .then(response=>{
            const contentStateString = response.data.draft_js_raw_state_string
            if (contentStateString){
                setEditorState(deserializeContentState(contentStateString))
            }
        })
        .catch(error=>{
            console.log(error)
        })
    }
    const focusEditor = () => {
        editor.current.focus();
    }
    const handleChange = (es) => {
        setEditorState(es)
    }
    const serializedEditorState = () => {
        const contentState = convertToRaw(editorState.getCurrentContent())
        const contentStateString = JSON.stringify(contentState)
        return contentStateString
    }
    const deserializeContentState = (contentStateString) => {
        const contentStateObject = JSON.parse(contentStateString)
        const contentState = convertFromRaw(contentStateObject)
        const editorState = EditorState.createWithContent(contentState)
        return editorState
    }
    const handlePublish = () => {
        request.put(`public-terms/${name}`, {
            name: name, 
            draft_js_raw_state_string: serializedEditorState()
        })
        .catch(error=>{
            console.log(error)
        })
    }
    const onInlineStyleClick = (style) => {
        handleChange(RichUtils.toggleInlineStyle(editorState, style));
    }
    const onInsertSoftLine = () => {
        const newState = RichUtils.insertSoftNewline(editorState);
        setEditorState(newState);
    }
    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    }
    const adjustDepth = (newDepth) => {
        const newState = RichUtils.onTab(
            new Event('keydown'),
            editorState,
            newDepth
        );
        setEditorState(newState);
    }
    useEffect(()=>{
        getTerm()
    }, [])
    return (
        <Accordion sx={{background: "none"}}>
            <AccordionSummary><h3>{name}</h3></AccordionSummary>
            <AccordionDetails>
                <div style={{marginBottom: 50, position: "relative", borderRadius: 20, border: "1px solid grey", padding: 20}}>
                    <Grid 
                        sx={{background: theme.palette.background.default}}
                        container justifyContent={"space-between"}  alignItems={"center"} 
                        position={"sticky"} top={0} zIndex={2}
                    >
                        <Grid item>
                            <Grid container alignItems={"center"}>
                                <Grid item paddingRight={3}>
                                    <h3>{name}</h3>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={(e)=>{onInlineStyleClick("BOLD")}}>
                                        <FormatBoldRoundedIcon />
                                    </IconButton>
                                    <IconButton onClick={(e)=>{onInsertSoftLine()}}>
                                        <KeyboardReturnRoundedIcon />
                                    </IconButton>
                                    <IconButton onClick={(e)=>{toggleBlockType('ordered-list-item')}}>
                                        <FormatListNumberedRoundedIcon />
                                    </IconButton>
                                    <IconButton onClick={(e)=>{toggleBlockType('unordered-list-item')}}>
                                        <FormatListBulletedRoundedIcon />
                                    </IconButton>
                                    <IconButton onClick={(e)=>{adjustDepth(4)}}>
                                        <FormatIndentIncreaseRoundedIcon />
                                    </IconButton>
                                    <IconButton onClick={(e)=>{adjustDepth(-1)}}>
                                        <FormatIndentDecreaseRoundedIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Button onClick={handlePublish}>Publish Changes</Button>
                        </Grid>
                    </Grid>
                    <div
                        style={{ minHeight: "6em", cursor: "text" }}
                        onClick={focusEditor}
                    >
                        <div style={{lineHeight: 2}}>
                            <Editor
                                ref={editor}
                                editorState={editorState}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    
    )
}

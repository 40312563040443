import { useNavigate } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link'
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';

import { formattedCurrency } from "@/utils";
import BidRowButtons from '@/bids/BidRowButtons';
import { request } from "@/Api";
import { BID_TYPES, BID_TYPE_MAIN, BID_TYPE_ALT, BID_ACTIVITY_REQUESTED_SIGNATURE, BID_ACTIVITY_SIGNED, BID_STATUS_SIGNED, BID_STATUS_DRAFT } from "@/bid/constants"
import { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { TopSheetPreview } from '@/bid/TopSheetPreview';
import { useBid } from '@/bid/bidHooks';
import { setError } from '@/nav/navSlice';
import { useDispatch } from 'react-redux';

export default function ProjectBids(props){
    const { bids, setBids, setProjectSigned, lockMainBid, projectCurrency, confidentialDataPermission, updatePermission, confirmedBid } = props
    const navigate = useNavigate()
    const bid_types = lockMainBid ? BID_TYPES.filter(type => type != BID_TYPE_MAIN) : BID_TYPES
    const [topsheetBidId, setTopsheetBidId] = useState(null)
    const getBid = useBid()
    const dispatch = useDispatch()

    const handleBidType = (bidId) => (event) => {
        const bidType = event.target.value
        setBids(bids.map(bid => bid.id == bidId ? {...bid, bid_type: bidType} : bid))

        request.patch(`/bids/${bidId}`, {bid_type: bidType}).then(() => {
            if (bidType == BID_TYPE_MAIN) {
                setBids(bids.map(bid => bid.id == bidId ? {...bid, bid_type: BID_TYPE_MAIN} :
                    bid.bid_type == BID_TYPE_MAIN ? {...bid, bid_type: BID_TYPE_ALT} :
                    bid
                ))   
            }
        })
    }

    const handleOpenBid = (url) => (event) => {
        event.preventDefault()
        navigate(url)
    }

    const handleBidDeleteConfirm = (bidId) => {
        request.delete(`/bids/${bidId}`)
        .then(response => {
            setBids([...response.data])
        })
        .catch(err => {
            if(err.response.status == 400){
                dispatch(setError({message: err.response.data.detail}))
            }
        })
    }
    
    // TODO: create and move to a FilesUploaderForBidEstimates component
    const handleBidFileUploaded = (bidId, files) => {
        request.post(`/bids/${bidId}/activity`, {action: BID_ACTIVITY_SIGNED, note: "Confirm client signed by uploading existing signature"})
        .then(()=>{
            setBids([...bids].map(bid => {
                return (bid.id != bidId) ? bid : {...bid, status: BID_STATUS_SIGNED, files: files}
            }))
        })
        setProjectSigned(files)
    }

    useEffect(()=>{
        if (topsheetBidId){
            getBid(topsheetBidId)
        }
    }, [topsheetBidId])

    return(
        <>
            <Modal open={topsheetBidId} onClose={()=>{setTopsheetBidId(null)}}>
                <div style={{position: "relative"}}>
                    <div style={{position: "absolute", left: "50%", transform: "translate(-50%)", background: "white"}}>
                        <TopSheetPreview frontend viewOnly/>
                    </div>
                </div>
            </Modal> 
            <TableContainer sx={{paddingBottom: 5}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Quoted</TableCell>
                            {confidentialDataPermission ? (
                                <TableCell align="right">GP</TableCell>
                            ) : null}
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">Status</TableCell>
                            {updatePermission || !confidentialDataPermission ? <TableCell align="right"></TableCell> : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bids ? bids.map((bid)=> {
                            return(
                                <TableRow key={bid.id}>
                                    <TableCell>
                                        {updatePermission ? (
                                            <Link href={`/bids/${bid.id}`} color="inherit" onClick={handleOpenBid(`/bids/${bid.id}`)}>
                                                {bid.name}
                                            </Link>
                                        ) : bid.name}
                                    </TableCell>
                                    <TableCell align="right">{formattedCurrency(bid.client_quoted, projectCurrency)}</TableCell>
                                    {confidentialDataPermission ? (
                                        <TableCell align="right">{formattedCurrency(bid.client_gross_profit, projectCurrency)}</TableCell>
                                    ) : null}
                                    <TableCell align="right">
                                        {updatePermission ? (
                                            <Select
                                                value={bid.bid_type}
                                                onChange={handleBidType(bid.id)}
                                                input={<Input disableUnderline />}
                                                renderValue={(value) => (
                                                    <Chip label={value} />
                                                )}
                                                readOnly={bid.bid_type == BID_TYPE_MAIN}
                                            >
                                                {bid_types.map((type, i) => 
                                                    !(bid.status == BID_STATUS_DRAFT && type == BID_TYPE_MAIN && confirmedBid) && 
                                                    <MenuItem value={type}>
                                                        {type}
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        ) : (
                                            <Chip label={bid.bid_type} />
                                        )}
                                    </TableCell>
                                    <TableCell align="right">{bid.status}</TableCell>
                                    <TableCell align="right">
                                        <Grid container justifyContent={"end"}>
                                            <BidRowButtons
                                                bid={bid}
                                                handleBidFileUploaded={handleBidFileUploaded}
                                                openTopsheet={()=>{setTopsheetBidId(bid.id)}}
                                                handleDelete={()=>handleBidDeleteConfirm(bid.id)}
                                                readOnly={!updatePermission}
                                                setTopsheetBidId={setTopsheetBidId}
                                            />
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            )
                        }) : null}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

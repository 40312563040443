import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"

import { request } from "@/Api"
import { setToken } from "@/auth/authSlice"
import ApiAutocomplete from "@/common/ApiAutocomplete"
import { CustomBox, Heading } from "@/common/StyledComponents"
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Modal, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, useTheme } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import BaseOrganizationComponents from "@/common/BaseOrganizationComponents"

const SyncButtonGridItem = ({ name, route, caution, notes }) => {
    const theme = useTheme()
    const [lastActionStatus, setLastActionStatus] = useState(null)
    const [isSyncing, setIsSyncing] = useState(false)
    const testSyncData = (endpoint) => {
        setIsSyncing(true)
        request.get(endpoint)
        .then((response)=>{
            setLastActionStatus(JSON.stringify(response))
        })
        .catch((error)=>{
            setLastActionStatus(JSON.stringify(error))
        })
        .finally(() => {
            setIsSyncing(false)
        })
    }
    return (
        <Grid item md={3} xs={6}>
            <div style={{background: theme.palette.background.paper, minHeight: 100, padding: 10, borderRadius: 10}}>
                {isSyncing?<CircularProgress/>:
                <Button color={caution ? "error" : "primary"} variant="contained" onClick={()=>{testSyncData(route)}}>
                    { `${name}${caution ? " (use with caution)" : ""}` }
                </Button>}
                <span style={{paddingLeft: 10, color: "grey"}}>{notes}</span>
                {lastActionStatus ? <Grid item xs={12} md={12}>Last Action: {lastActionStatus}</Grid> : null }
            </div>
        </Grid>
    )

}

export default function Admin() {
    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [personId, setPersonId] = useState("")

    const [activeFinancialTerm, setActiveFinancialTerm] = useState(null)
    const [financialTerms, setFinancialTerms] = useState([])
    const [financialTermPopupOpen, setFinancialTermPopupOpen] = useState(false)

    const loginAs = () => {
        if (!personId) {
            return
        }
        request.post(`login-as/${personId}`, {})
        .then(function (response) {            
            dispatch(setToken(response.data))
            navigate("/projects")
        })
        .catch(function (error) {
            error.defaultErrorResponse()
        })
    }

    const authorizeQuickbooks = () => {
        request.get("quickbooks_authorize", { maxRedirects: 0 })
        .then(response => {
            window.location = response.data
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getFinancialTerms = () => {
        request.get("financial-terms")
        .then(response=>{
            setFinancialTerms(response.data)
        })
    }

    const handleOpenPopup = () => {
        setFinancialTermPopupOpen(true)
    }

    const handleClosePopup = () => {
        setFinancialTermPopupOpen(false)
        setActiveFinancialTerm(null)
    }

    const handleEditButton = (term) => () => {
        setActiveFinancialTerm(term)
        setFinancialTermPopupOpen(true)
    }

    const handleSwitchChange = (term) => () => {
        request.put(`/financial-terms/${term.id}`, {...term, public: !term.public})
        .then(response=>{
            setFinancialTerms(financialTerms.map(term=>term.id==response.data.id ? response.data : term))
        })
    }

    useEffect(()=>{
        getFinancialTerms()
    }, [])

    return (
        <>
            <Heading style={{paddingBottom: 30}}>Admin Panel</Heading>
            <h3>Impersonation</h3>
            <Grid container alignItems="center" justifyContent="flex-start" spacing={2} paddingBottom={3}>
                <Grid item>
                    <ApiAutocomplete
                        size={"small"}
                        sx={{width: 400}}
                        value={personId}
                        apiRoute={"persons"}
                        fields={["roles_string", "first_name", "last_name", "id"]}
                        onChange={(e, v)=>{setPersonId(v)}}
                    />
    
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={loginAs}>
                        Login As
                    </Button>
                </Grid>
            </Grid>

            <Accordion>
                <AccordionSummary>
                    <Grid container alignItems={"center"} spacing={3}>
                        <Grid item>
                            <h3>Financial Terms</h3>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={handleOpenPopup}>Add</Button>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container maxHeight={500} overflow={"auto"}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Term Description</TableCell>
                                    <TableCell>Billing Schedule</TableCell>
                                    <BaseOrganizationComponents>
                                        <TableCell>Public</TableCell>
                                    </BaseOrganizationComponents>
                                    <TableCell></TableCell>
                                </TableRow>
                                {financialTerms.map((term, index)=>
                                    <TableRow key={index}>
                                        <TableCell>{term.name}</TableCell>
                                        <TableCell>{term.description}</TableCell>
                                        <TableCell>{term.billing_schedule}</TableCell>
                                        <BaseOrganizationComponents>
                                            <TableCell>
                                                <Switch checked={term.public} onChange={handleSwitchChange(term)}/>
                                            </TableCell>
                                        </BaseOrganizationComponents>
                                        <TableCell width={50}>
                                            <IconButton onClick={handleEditButton(term)}>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <h3>Hotkeys</h3>
            <Grid container spacing={3} paddingBottom={5}>
                <SyncButtonGridItem name="Update Signed Project Calculated Fields" route="/admin/update-all-sign-project-calculated-fields" notes="Recalculating project fields with properties"/>
                <SyncButtonGridItem name="Update All Calculated Fields" route="/admin/update-all-calculated-fields" notes="Update all model's calculated fields with their properties"/>
                <SyncButtonGridItem name="Sync QB Invoices" route="/invoices/refersh-invoices-test" notes="Update Invoice table, add if missing, archive if no longer in QB response"/>
                <SyncButtonGridItem name="Sync QB Income Summary" route="/admin/refresh-customer-income-test" notes="Refresh project accounting numbers from QB (from invoice total)"/>
                <SyncButtonGridItem name="Sync QB Income Summary (TMP)" route="/admin/refresh-customer-income-tmp-test" notes="Refresh project accounting numbers from QB (from qb report)"/>
                <SyncButtonGridItem name="Sync Projects Forecast Hours" route="/admin/refresh-projects-forecast-hours" notes="Update project hours count from Forecast (should be removed soon)"/>
                <SyncButtonGridItem name="Sync Exchange Rates" route="/exchange-rates/refresh" notes="Update Exchange Rate table daily"/>
                <SyncButtonGridItem name="Sync Accounting Bills" route="/accounting-bills/refersh-accounting-bills" notes="Update Accounting Bills table by inserting any new QB bills"/>
                <SyncButtonGridItem name="Sync Accounting Bill Attachments" route="/accounting-bill-attachments/refersh" notes="Add Attachables if they are being newly created"/>
                <SyncButtonGridItem name="Sync Project Float Actual" route="/admin/refresh-all-project-float-actual" notes="Update all project Internal Actuals from Float"/>
                <SyncButtonGridItem name="Sync All Float Projects" route="/admin/refresh-all-float-projects" notes="Correct Float projects, treating MC as source of truth"/>
                <SyncButtonGridItem name="Sync Float IDs" route="/admin/refresh-float-ids" notes="Update float ids in MC, will become less useful as data are initially migrated to float"/>
                <SyncButtonGridItem name="Sync All Bid Sheets" route="/admin/refresh-all-bid-test" notes="Update project's legacy bid sheet SUM data" caution/>
            
                <Grid item md={3} xs={6}>
                    <div style={{background: theme.palette.background.paper, minHeight: 100, padding: 10, borderRadius: 10}}>
                        <Button variant="contained" onClick={authorizeQuickbooks}>
                            Authorize Quickbooks
                        </Button>
                    </div>
                </Grid>
            </Grid>
            
            <FinancialTermPopup 
                open={financialTermPopupOpen}
                closePopup={handleClosePopup}
                financialTerms={financialTerms}
                setFinancialTerms={setFinancialTerms}
                activeFinancialTerm={activeFinancialTerm}
            />
        </>
    )
}

const FinancialTermPopup = ({open, closePopup, setFinancialTerms, financialTerms, activeFinancialTerm}) => {
    const theme = useTheme()
    const [financialTerm, setFinancialTerm] = useState({})
    const handleInputChange = (field) => (event) => {
        setFinancialTerm({...financialTerm, [field]: event.target.value})
    }
    // const setProjectTypeId = (value) => {
    //     setFinancialTerm({...financialTerm, "project_type_id": value})
    // }
    const addFinancialTerm = () => {
        request.post(`/financial-terms`, { 
            is_custom: false,
            ...financialTerm
        }).then(response=>{
            closePopup()
            setFinancialTerms([...financialTerms, response.data])
        })
    }
    const updateFinancialTerm = () => {
        request.put(`/financial-terms/${activeFinancialTerm.id}`, {
            ...financialTerm
        }).then(response=>{
            closePopup()
            setFinancialTerms(financialTerms.map(term=>term.id == response.data.id ? response.data : term))
        })
    }
    useEffect(()=>{
        setFinancialTerm(activeFinancialTerm || {})
    }, [open, activeFinancialTerm])
    return (
        <Modal open={open} onClose={closePopup}>
            <CustomBox style={{background: theme.palette.background.paper, overflow: "auto"}}>
                <Heading>{`${activeFinancialTerm ? "Update" : "Add"} Financial Term`}</Heading>
                <h3>Name</h3>
                <TextField 
                    fullWidth
                    onChange={handleInputChange("name")}
                    value={financialTerm.name || ""}
                    size="small"
                />
                <h3>Term description</h3>
                <TextField 
                    fullWidth
                    multiline
                    rows={5}
                    onChange={handleInputChange("description")}
                    value={financialTerm.description || ""}
                    size="small"
                />
                <h3 style={{marginBottom: 0}}>Billing schedule</h3>
                <span style={{fontSize: 12, lineHeight: 2.5, color: "grey"}}>example: 60/30/10</span><br/>
                <TextField 
                    sx={{paddingBottom: 3}}
                    fullWidth
                    onChange={handleInputChange("billing_schedule")}
                    value={financialTerm.billing_schedule || ""}
                    size="small"
                />
                {/* <h3>Project Type</h3>
                <ApiAutocomplete
                    size={"small"}
                    sx={{width: "100%", paddingBottom: 3}}
                    value={financialTerm.project_type_id}
                    apiRoute={"project-types"}
                    fields={["name"]}
                    onChange={(e, v)=>{setProjectTypeId(v)}}
                /> */}
                <Button variant="contained" onClick={activeFinancialTerm ? updateFinancialTerm : addFinancialTerm}>
                    {`${activeFinancialTerm ? "Update" : "Create"}`}
                </Button>
            </CustomBox>
        </Modal>
    )
}
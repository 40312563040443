import { useEffect, useState } from "react"

import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"

import { request as requestNormal, requestSilent } from "@/Api"

export default function ApiAutocomplete (props){
    const { apiRoute, apiFilters, fields, fieldSeperator, error, helperText, label, fullOptionData, inputStyle, simple, disabled, readOnly, silentErrors, additionalOptions, placeholder, onInputResponseLimit, onLoadResponseData, variant, InputProps, ...passThroughProps } = props
    const [serverEntities, setServerEntities] = useState([])
    const [entities, setEntities] = useState([])
    const [loadError, setLoadError] = useState(false)
    const customInputProps = InputProps || {}
 
    const handleTextFieldChange = (event) => {
        if (onInputResponseLimit){
            // TODO: debounce request
            getEntities(silentErrors ? requestSilent : requestNormal)(event.target.value)
        }
    }

    const getEntities = (request) => (searchString) => {
        if (readOnly || disabled) {
            return
        }

        request.get(apiRoute, {
            params: {
                offset: 0,
                limit: onInputResponseLimit != null ? onInputResponseLimit : 0,
                simple: simple,
                ...apiFilters,
                name: onInputResponseLimit != null ? searchString : null
            }
        })
        .then((response)=>{
            if(onLoadResponseData){
                onLoadResponseData(response.data)
            }
            setServerEntities(response.data.items ? response.data.items : response.data)
        })
        .catch((error) => {
            if (!disabled) {
                setLoadError(true)
            }
        })
    }

    useEffect(getEntities(silentErrors ? requestSilent : requestNormal), [readOnly, disabled])

    useEffect(() => {
        const additionalEntites = additionalOptions ? additionalOptions : []
        setEntities([
            ...serverEntities,
            ...additionalEntites,
        ])
    }, [serverEntities, additionalOptions])

    if (fullOptionData){
        passThroughProps.isOptionEqualToValue = (option, value) => {
            return option.id == value.id
        }
    }

    return (
        <Autocomplete
            { ...passThroughProps }
            disabled={disabled || loadError}
            readOnly={readOnly}
            disablePortal
            options={fullOptionData ? entities : entities.map((entity)=>entity.id)}
            getOptionLabel={(idOrObject) => {
                let entity = fullOptionData ? idOrObject : entities.find((entity)=>idOrObject==entity.id)
                let labels = []
                if (entity){
                    for (var i in fields){
                        labels.push(entity[fields[i]])
                    }
                }
                return labels.join(fieldSeperator || " ")
            }}
            renderInput={(params) => <TextField {...params} onChange={handleTextFieldChange} sx={inputStyle} label={label} error={error} helperText={helperText} placeholder={placeholder} variant={variant || "outlined"} InputProps={{...params.InputProps, ...customInputProps}} />}
        />
    )
}
